import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Checkbox from "@material-ui/core/Checkbox"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import FormControl from "@material-ui/core/FormControl"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormLabel from "@material-ui/core/FormLabel"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import InputLabel from "@material-ui/core/InputLabel"
import LinearProgress from "@material-ui/core/LinearProgress"
import MenuItem from "@material-ui/core/MenuItem"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { navigate } from "gatsby"
import React from "react"
import SwipeableViews from "react-swipeable-views"
import passwordRecoveryGif from "src/images/mainPic/icon_letter.gif"
import hitRateIcon from "src/images/mainPic/signInIcon.png"
import swal from "sweetalert"

import { registerError, registerSuccess } from "../entity/alert/swalErrorList"
import Theme from "../entity/theme/theme"
import RegisterStepper from "../presentation/organisms/register/registerStepper"
import { termsOfService } from "../presentation/organisms/register/termsOfService"
import { registerRepository } from "../repository/action"

const useStyles = makeStyles((theme) => ({
    root: {
        textAlign: "center",
        fontSize: "0.875rem",
        fontFamily: Theme.font.fontFamily,
        borderRadius: "8px",
        border: "1px solid #dadce0",
        maxWidth: "100%",
        position: "absolute",
        height: "560px",
        left: "50%",
        top: "50%",
        marginLeft: "-50%",
        marginTop: "-280px",
        marginBottom: "10px",
        width: "100%",
    },
    title: {
        display: "inline-flex",
        position: "absolute",
        top: "0px",
        left: "0px",
    },
    hitRateTitle: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        color: "#3f51b5",
        margin: "5px auto",
        fontSize: "24px",
        alignItems: "center",
    },
    imgStyle: {
        width: "40px",
        height: "40px",
        maxWidth: "215px",
        margin: "3px auto 0px auto",
        alignItems: "center",
    },
    mainBoxTitle: {
        margin: "30px auto",
    },
    error: {
        "& .MuiTextField-root": {
            textAlign: "center",
            margin: theme.spacing(1),
            width: 200,
        },
    },
    errorTell: {
        color: "#ff0000",
        textAlign: "center",
    },
    formControl: {
        margin: "5px",
        minWidth: 120,
    },
    colorPosition: {
        position: "relative",
        top: "-1px",
        margin: theme.spacing(3),
    },

    toLoginPage: {
        marginTop: "30px",
        position: "absolute",
        right: "0px",
        bottom: "5px",
    },

    centerStyle: {
        textAlign: "center",
    },
    font: {
        fontFamily: Theme.font.fontFamily,
    },
}))

//登録画面
function Register() {
    const [registerData, setRegisterData] = React.useState({
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        confirmPassword: "",
        belong: "",
        teamId: "",
        teamName: "",
        type: "",
        gender: "man",
        rank: "0",
        termsOfService: false,
    })
    const [registerFlag, setRegisterFlag] = React.useState(true)
    const [passwordErrorFlag, setPasswordErrorFlag] = React.useState(false)
    const [passwordError, setPasswordError] = React.useState("")
    const [values, setValues] = React.useState({
        password: "",
        showPassword: false,
    })
    const [emailErrorFlag, setEmailErrorFlag] = React.useState(false)
    const [emailError, setEmailError] = React.useState("")
    const [linearProgressAnimation, setLinearProgressAnimation] = React.useState(false)
    const [activeStep, setActiveStep] = React.useState(0)
    const [open, setOpen] = React.useState(false)
    const [team, setTeam] = React.useState()
    const [termsOfServiceFlag, setTermsOfServiceFlag] = React.useState(true)
    const classes = useStyles()

    const which = ["juniorhighschool", "highschool", "college", "ordinary"]

    const registerDataChange = (name, name2) => (event) => {
        if (name == "belong") {
            setRegisterData({
                ...registerData,
                [name]: event.target.value,
                teamId: "",
                teamName: "",
            })
        } else if (name == "termsOfService") {
            setRegisterData({ ...registerData, [name]: event.target.checked })
        } else if (name == "teamId") {
            let value = event.target.value
            let team = value.split("&")
            setRegisterData({ ...registerData, [name]: team[0], [name2]: team[1] })
        } else {
            setRegisterData({ ...registerData, [name]: event.target.value })
        }
    }

    const checkEmail = (targetData) => {
        let arrayTargetData = [...targetData]
        let flag = arrayTargetData.some((value) => {
            return !(value.match(/[a-z 0-9 @ .]/i) == null || targetData.search("@") == -1)
        })
        return flag
    }

    const checkPassword = (targetData) => {
        let arrayTargetData = [...targetData]
        let flag = arrayTargetData.some((value) => {
            return !(
                value.match(/[a-z 0-9]/i) == null ||
                targetData.search(/[0-9]/) == -1 ||
                targetData.search(/[a-z]/) == -1
            )
        })
        return flag
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        setTermsOfServiceFlag(false)
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword })
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault()
    }

    const handleNext = () => {
        switch (activeStep) {
            case 0:
                setEmailErrorFlag(false)
                setPasswordErrorFlag(false)
                if (registerData.password == registerData.confirmPassword) {
                    if (checkPassword(registerData.password)) {
                        if (registerData.password.length > 7) {
                            if (checkEmail(registerData.email)) {
                                setLinearProgressAnimation(true)
                                setRegisterFlag(true)
                                registerRepository
                                    .userEmailCheck(registerData)
                                    .then((result) => {
                                        if (result == "ok") {
                                            registerRepository
                                                .teamFetch()
                                                .then((data) => {
                                                    setTeam(data)
                                                    setLinearProgressAnimation(false)
                                                    setRegisterFlag(false)
                                                    setActiveStep(
                                                        (prevActiveStep) => prevActiveStep + 1
                                                    )
                                                })
                                                .catch(() => {
                                                    swal({
                                                        title: "エラーが発生しました",
                                                        icon: "error",
                                                        button: false,
                                                    }).then(() => {
                                                        navigate("/")
                                                    })
                                                })
                                        } else {
                                            setEmailErrorFlag(true)
                                            setLinearProgressAnimation(false)
                                            setRegisterFlag(false)
                                            setEmailError(
                                                "このメールアドレスは既に使用されています"
                                            )
                                        }
                                    })
                                    .catch(() => {
                                        swal({
                                            title: "エラーが発生しました",
                                            icon: "error",
                                            button: false,
                                        }).then(() => {
                                            navigate("/")
                                        })
                                    })
                            } else {
                                setEmailErrorFlag(true)
                                setEmailError("メールアドレスを入力してください")
                            }
                        } else {
                            setPasswordErrorFlag(true)
                            setPasswordError("8文字以上で入力してください")
                        }
                    } else {
                        setPasswordErrorFlag(true)
                        setPasswordError("半角英数字を組み合わせて入力してください")
                    }
                } else {
                    setPasswordErrorFlag(true)
                    setPasswordError("パスワードが確認と異なります")
                }
                break
            case 1:
                setLinearProgressAnimation(true)
                setRegisterFlag(true)
                registerRepository
                    .register(registerData)
                    .then(() => {
                        return registerRepository
                            .registerMailSend(registerData)
                            .then(() => {
                                return (
                                    setRegisterFlag(false),
                                    setLinearProgressAnimation(false),
                                    swal(registerSuccess).then((val) => {
                                        if (val) {
                                            setActiveStep((prevActiveStep) => prevActiveStep + 1)
                                        }
                                    })
                                )
                            })
                            .catch(() => {
                                return setLinearProgressAnimation(false), swal(registerError)
                            })
                    })
                    .catch(() => {
                        return (
                            setLinearProgressAnimation(false),
                            swal(registerError),
                            setRegisterFlag(false)
                        )
                    })
                break
            case 2:
                break
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    React.useEffect(() => {
        if (
            activeStep == 0 &&
            registerData.firstName.length > 0 &&
            registerData.lastName.length > 0 &&
            registerData.email.length > 0 &&
            registerData.password.length > 0 &&
            registerData.confirmPassword.length > 0
        ) {
            setRegisterFlag(false)
        } else if (
            activeStep == 1 &&
            registerData.teamId.length > 0 &&
            registerData.teamName.length > 0 &&
            registerData.type.length > 0 &&
            registerData.termsOfService
        ) {
            setRegisterFlag(false)
        } else {
            setRegisterFlag(true)
        }
    }, [registerData, activeStep])

    return (
        <>
            <Card className={classes.root}>
                {linearProgressAnimation && <LinearProgress />}
                <CardContent>
                    <div className={classes.title}>
                        <img src={hitRateIcon} className={classes.imgStyle} />
                        <Typography className={classes.hitRateTitle}>HitRate</Typography>
                    </div>
                    <div>
                        <Typography
                            style={{ margin: "30px auto 0px auto", fontSize: "24px" }}
                            className={classes.font}
                        >
                            HitRateアカウントの作成
                        </Typography>
                        <RegisterStepper
                            activeStep={activeStep}
                            setActiveStep={setActiveStep}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            registerFlag={registerFlag}
                        >
                            <SwipeableViews index={activeStep} disabled>
                                <div
                                    style={
                                        (Object.assign({}),
                                        { overflow: "hidden", maxWidth: "85%;", margin: "auto" })
                                    }
                                >
                                    <div style={{ margin: "0px 6%" }}>
                                        <TextField
                                            inputProps={{ style: { padding: "12px 15px" } }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            label="性"
                                            fullWidth
                                            onChange={registerDataChange("lastName")}
                                        />
                                        <TextField
                                            inputProps={{ style: { padding: "12px 15px" } }}
                                            variant="outlined"
                                            margin="normal"
                                            required
                                            label="名"
                                            fullWidth
                                            onChange={registerDataChange("firstName")}
                                        />
                                        <TextField
                                            inputProps={{ style: { padding: "12px 15px" } }}
                                            autoComplete="new-password"
                                            error={emailErrorFlag}
                                            margin="normal"
                                            required
                                            label="メールアドレス"
                                            variant="outlined"
                                            fullWidth
                                            onChange={registerDataChange("email")}
                                        />
                                    </div>
                                    {emailErrorFlag == false ? (
                                        <FormHelperText className={classes.centerStyle}>
                                            文字化けが起きるためsoftbankメールは非推奨
                                        </FormHelperText>
                                    ) : (
                                        <FormHelperText className={classes.centerStyle}>
                                            <span style={{ color: "red" }}>{emailError}</span>
                                        </FormHelperText>
                                    )}
                                    <div style={{ margin: "-5px 6%", textAlign: "left" }}>
                                        <div style={{ marginTop: "16px", marginBottom: "8px" }}>
                                            <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                                パスワード*
                                            </div>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            >
                                                {/* <InputLabel htmlFor="outlined-adornment-password">パスワード</InputLabel> */}
                                                <OutlinedInput
                                                    fullWidth
                                                    autoComplete="new-password"
                                                    error={passwordErrorFlag}
                                                    inputProps={{ style: { padding: "12px 15px" } }}
                                                    type={values.showPassword ? "text" : "password"}
                                                    value={registerData.password}
                                                    onChange={registerDataChange("password")}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={
                                                                    handleMouseDownPassword
                                                                }
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                        <div style={{ marginTop: "16px", marginBottom: "8px" }}>
                                            <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>
                                                確認*
                                            </div>
                                            <FormControl
                                                variant="outlined"
                                                style={{ width: "100%" }}
                                            >
                                                <OutlinedInput
                                                    fullWidth
                                                    autoComplete="new-password"
                                                    error={passwordErrorFlag}
                                                    inputProps={{ style: { padding: "12px 15px" } }}
                                                    type={values.showPassword ? "text" : "password"}
                                                    value={registerData.confirmPassword}
                                                    onChange={registerDataChange("confirmPassword")}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                onMouseDown={
                                                                    handleMouseDownPassword
                                                                }
                                                                edge="end"
                                                            >
                                                                {values.showPassword ? (
                                                                    <Visibility />
                                                                ) : (
                                                                    <VisibilityOff />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                    {passwordErrorFlag == false ? (
                                        <FormHelperText className={classes.centerStyle}>
                                            半角英数字を組み合わせて8文字以上で入力してください
                                        </FormHelperText>
                                    ) : (
                                        <FormHelperText className={classes.centerStyle}>
                                            <span style={{ color: "red" }}>{passwordError}</span>
                                        </FormHelperText>
                                    )}
                                </div>
                                <div
                                    style={
                                        (Object.assign({}),
                                        { overflow: "hidden", margin: "2% auto 0%" })
                                    }
                                >
                                    <div>
                                        <FormControl
                                            component="fieldset"
                                            className={classes.positionPosition}
                                        >
                                            <FormLabel component="legend" required>
                                                性別
                                            </FormLabel>
                                            <RadioGroup
                                                aria-label="gender"
                                                name="gender"
                                                defaultValue="man"
                                                onChange={registerDataChange("gender")}
                                            >
                                                <FormControlLabel
                                                    value="man"
                                                    control={<Radio />}
                                                    label="男子"
                                                />
                                                <FormControlLabel
                                                    value="woman"
                                                    control={<Radio />}
                                                    label="女子"
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                    <div>
                                        <FormControl className={classes.formControl} required>
                                            <InputLabel>所属</InputLabel>
                                            <Select
                                                value={registerData.belong}
                                                onChange={registerDataChange("belong")}
                                            >
                                                <MenuItem value={"1"}>中学生</MenuItem>
                                                <MenuItem value={"2"}>高校生</MenuItem>
                                                <MenuItem value={"3"}>大学生</MenuItem>
                                                <MenuItem value={"4"}>一般</MenuItem>
                                            </Select>
                                            <FormHelperText>所属を選んでください</FormHelperText>
                                        </FormControl>
                                    </div>
                                    <FormControl className={classes.formControl} required>
                                        <InputLabel>所属校</InputLabel>
                                        <Select
                                            value={
                                                registerData.teamId + "&" + registerData.teamName
                                            }
                                            onChange={registerDataChange("teamId", "teamName")}
                                        >
                                            <MenuItem value={""}></MenuItem>
                                            {team &&
                                                registerData.belong.length > 0 &&
                                                team[which[Number(registerData.belong) - 1]].map(
                                                    (value) => {
                                                        if (Object.keys(value).length > 0) {
                                                            return (
                                                                <MenuItem
                                                                    value={
                                                                        value.id + "&" + value.name
                                                                    }
                                                                >
                                                                    {value.name}
                                                                </MenuItem>
                                                            )
                                                        }
                                                    }
                                                )}
                                        </Select>
                                        <FormHelperText>所属校選んでください</FormHelperText>
                                    </FormControl>
                                    <FormControl className={classes.formControl} required>
                                        <InputLabel>役職</InputLabel>
                                        <Select
                                            value={registerData.type}
                                            onChange={registerDataChange("type")}
                                        >
                                            <MenuItem value={""}></MenuItem>
                                            <MenuItem value={"member"}>通常部員</MenuItem>
                                            <MenuItem value={"leaders"}>幹部部員</MenuItem>
                                            <MenuItem value={"ob"}>OB</MenuItem>
                                        </Select>
                                        <FormHelperText>役職を選んでください</FormHelperText>
                                    </FormControl>
                                    {/* </div> */}
                                    <div>
                                        <div>
                                            <Checkbox
                                                disabled={termsOfServiceFlag}
                                                checked={registerData.termsOfService}
                                                onChange={registerDataChange("termsOfService")}
                                                inputProps={{
                                                    "aria-label": "primary checkbox",
                                                }}
                                            />
                                            <span>
                                                <a
                                                    onClick={handleOpen}
                                                    style={{ color: "#3f51b5" }}
                                                >
                                                    利用規約
                                                </a>
                                                に同意する
                                            </span>
                                            <div>*利用規約を読まなければチェックできません</div>
                                        </div>
                                        <Dialog
                                            disableBackdropClick
                                            disableEscapeKeyDown
                                            open={open}
                                            onClose={handleClose}
                                        >
                                            <DialogTitle>利用規約</DialogTitle>
                                            <DialogContent>
                                                {termsOfService}
                                                <DialogActions>
                                                    <Button onClick={handleClose} color="primary">
                                                        閉じる
                                                    </Button>
                                                </DialogActions>
                                            </DialogContent>
                                        </Dialog>
                                    </div>
                                </div>
                                <div
                                    style={
                                        (Object.assign({}),
                                        { overflow: "hidden", maxWidth: "85%;", margin: "auto" })
                                    }
                                >
                                    <div style={{ margin: "0px 6%" }}>
                                        <div style={{ marginTop: "95px" }}>
                                            <img
                                                src={passwordRecoveryGif}
                                                style={{ width: "auto", height: "100px" }}
                                            ></img>
                                            <Typography variant="h6" className={classes.font}>
                                                メールをご確認ください
                                            </Typography>
                                            <Typography variant="h7">
                                                HitRate から仮登録メールを
                                                <br />
                                                {registerData.email}に送信しました。
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            </SwipeableViews>
                        </RegisterStepper>
                    </div>
                </CardContent>
            </Card>
        </>
    )
}

export default Register
