import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import React from "react"

import Theme from "../../../entity/theme/theme"

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "5px auto",
        padding: "0px",
    },
    backButton: {
        marginRight: "0px",
        fontFamily: Theme.font.fontFamily,
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    toLoginPage: {
        fontFamily: Theme.font.fontFamily,
        position: "absolute",
        right: "0px",
        bottom: "-4px",
    },
    font: {
        fontFamily: Theme.font.fontFamily,
    },
    buttonPosition: {
        position: "relative",
        top: "-8px",
        marginRight: "auto",
        display: "flex",
    },
    buttonFont: {
        fontSize: "11px",
        fontFamily: Theme.font.fontFamily,
        textTransform: "none",
    },
}))

export default function RegisterStepper(props) {
    const { activeStep, handleNext, handleBack, registerFlag } = props
    const classes = useStyles()

    const toSignIn = () => {
        navigate("/signIn")
    }

    return (
        <div className={classes.root}>
            <div>
                <div>{props.children}</div>
                <div style={{ marginTop: "10px" }}>
                    <Button
                        disabled={activeStep === 0 || activeStep === 2}
                        onClick={handleBack}
                        className={classes.backButton}
                    >
                        戻る
                    </Button>
                    <Button
                        disabled={registerFlag}
                        variant="contained"
                        color="primary"
                        className={classes.font}
                        onClick={handleNext}
                    >
                        {activeStep === 1 ? "登録する" : "次へ"}
                    </Button>
                </div>
            </div>
            <div className={classes.buttonPosition}>
                <Button onClick={toSignIn} color="primary" className={classes.buttonFont}>
                    SignIn画面に戻る
                </Button>
            </div>
        </div>
    )
}
